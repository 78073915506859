<template>
    <modal :show="showModal" size="lg" @close="onModalClose">
        <template v-slot:header>
            <h5 class="modal-title" id="modalTitle">{{ title }}</h5>
        </template>
        <div>
            <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <b-row>
                        <b-col sm="12">
                            <p class="text-primary"><strong>ទឹកប្រាក់ត្រូវទទួលបាន</strong></p>
                            <hr class="mt-0 bg-primary">
                        </b-col>
                        <b-col sm="6">
                            <base-input
                                    v-model="meta.income.others"
                                    type="number"
                                    step="any"
                                    name="other_amount"
                                    label="ប្រាក់ឧបត្ថម្ភផ្សេងៗ">
                                </base-input>
                        </b-col>
                        <b-col sm="6">
                            <base-input
                                    v-model="meta.income.position"
                                    type="number"
                                    step="any"
                                    name="other_amount"
                                    label="ប្រាក់ឧបត្ថម្ភមុខដំណែង">
                                </base-input>
                        </b-col>
                        <b-col sm="6">
                            <base-input
                                    v-model="meta.income.commission"
                                    type="number"
                                    step="any"
                                    name="other_amount"
                                    label="ប្រាក់កម្រៃ">
                                </base-input>
                        </b-col>
                        <b-col sm="6">
                            <base-input
                                    v-model="meta.income.target_amount"
                                    type="number"
                                    step="any"
                                    name="other_amount"
                                    label="ប្រាក់សម្រេចបានគោលដៅកំណត់">
                                </base-input>
                        </b-col>
                        <b-col sm="6">
                            <base-input
                                    v-model="meta.income.incentive"
                                    type="number"
                                    step="any"
                                    name="other_amount"
                                    label="ប្រាក់ភាគលាភ">
                                </base-input>
                        </b-col>
                        <b-col sm="12">
                            <p class="text-primary"><strong>ទឹកប្រាក់ត្រូវកាត់</strong></p>
                            <hr class="mt-0 bg-primary">
                        </b-col>
                        <b-col sm="6">
                            <base-input
                                    v-model="meta.outcome.tax"
                                    type="number"
                                    step="any"
                                    name="other_amount"
                                    label="ពន្ធលើប្រាក់ខែ">
                                </base-input>
                        </b-col>
                        <b-col sm="6">
                            <base-input
                                    v-model="meta.outcome.unpaid_leave"
                                    type="number"
                                    step="any"
                                    name="other_amount"
                                    label="ការឈប់សម្រាក់មិនមានច្បាប់">
                                </base-input>
                        </b-col>
                        <b-col sm="6">
                            <base-input
                                    v-model="meta.outcome.disrepect"
                                    type="number"
                                    step="any"
                                    name="other_amount"
                                    label="មិនគោរពវិន័យ">
                                </base-input>
                        </b-col>
                        <b-col sm="6">
                            <base-input
                                    v-model="meta.outcome.advance_salary"
                                    type="number"
                                    step="any"
                                    name="other_amount"
                                    label="សុំបើកប្រាក់ខែមុន">
                                </base-input>
                        </b-col>
                        <b-col sm="6">
                            <base-input
                                    v-model="meta.outcome.insurrance"
                                    type="number"
                                    step="any"
                                    name="other_amount"
                                    label="ថ្លៃធានារ៉ាប់រ៉ង">
                                </base-input>
                        </b-col>
                        <b-col sm="6">
                            <base-input
                                    v-model="meta.outcome.others"
                                    type="number"
                                    step="any"
                                    name="other_amount"
                                    label="កក់ថ្លៃកាបូបដឹកជញ្ជូន">
                                </base-input>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </div>
        <template v-slot:footer>
            <base-button type="secondary" @click="showModal = false">Close</base-button>
            <base-button type="info" @click="() => $emit('downloadSingle', meta)">Download</base-button>
            <base-button @click="onSubmit" type="primary">Save</base-button>
        </template>
    </modal>
</template>
<script>
import { Select, Option } from 'element-ui';
export default {
    data() {
        return {
            title: '',
            showModal: false,
            meta: {
                income: {

                },
                outcome: {

                }
            }
        }
    },
    methods: {
        onSubmit() {
            this.$emit('onSubmit', this.meta);
        },
        show() {
            this.showModal = true;
            this.meta = {
                income: {

                },
                outcome: {

                }
            }
        },
        onModalClose() {
            this.hide();
        },
        hide() {
            this.showModal = false;
        },
    },
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
    }
}
</script>